import React from 'react';
import Waves from '../components/waves';
import Navigation from '../components/navigation';

import Footer from '../components/footer';

import '../css/typography.css';

const Booking = () => {
    return (
        <body className="leading-normal tracking-normal text-white gradient">
            <Navigation showButton={false} />
            <div className="pt-4">
                <Waves />
            </div>
            
            <section className="bg-white">
                <iframe
                    className="container max-w-5xl h-screen mx-auto spinner"
                    src="https://squareup.com/appointments/book/de2bzajlpbc5of/80QVMSMT3RY16/start"
                    title="booking" />
            </section>

            <div className="py-10 bg-white" />
            <Footer />
        </body>
    )
}

export default Booking;
